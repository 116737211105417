<template>
  <div class="container">
    <div class="public-content">
      <div class="login-container">
        <div class="left-item">
          <div class="img img-cover"><img src="../../assets/pc-images/login-img.png" /></div>
        </div>
        <div class="right-item">
          {{ msg }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "404",
  components: {
  },
  data() {
    return {
      msg: '404'
    }
  },
  mounted () {
    if (this.$route.query && this.$route.query.msg) {
      this.msg = this.$route.query.msg
    }
  },
  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.login-container {
  padding: 0 40px 0 52px;
  min-height: calc(100vh - 66px);
  display: flex;
  justify-content: center;
  align-items: center;
  .left-item {
    .img {
      width: 560px;
      height: 428px;
      margin-top: 12px;
    }
  }
  .right-item {
    width: 420px;
    padding: 60px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    color: #333;
  }
}
</style>
